import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-fresno-california.png'
import image0 from "../../images/cities/scale-model-of-fac-water-tower-in-fresno-california.png"
import image1 from "../../images/cities/scale-model-of-arte-américas-in-fresno-california.png"
import image2 from "../../images/cities/scale-model-of-clovis---big-dry-creek-museum-in-fresno-california.png"
import image3 from "../../images/cities/scale-model-of-fresno-chaffee-zoo-in-fresno-california.png"
import image4 from "../../images/cities/scale-model-of-meux-home-museum-in-fresno-california.png"
import image5 from "../../images/cities/scale-model-of-oso-de-oro-park-in-fresno-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Fresno'
            state='California'
            image={image}
            lat='36.7377981'
            lon='-119.78712469999999'
            attractions={ [{"name": "FAC Water Tower", "vicinity": "FAC Water Tower, 2444 Fresno St, Fresno", "types": ["point_of_interest", "establishment"], "lat": 36.7392069, "lng": -119.78722170000003}, {"name": "Arte Am\u00e9ricas", "vicinity": "1630 Van Ness Ave, Fresno", "types": ["art_gallery", "museum", "store", "point_of_interest", "establishment"], "lat": 36.7411456, "lng": -119.795792}, {"name": "Clovis - Big Dry Creek Museum", "vicinity": "401 Pollasky Ave, Clovis", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.8251413, "lng": -119.70188480000002}, {"name": "Fresno Chaffee Zoo", "vicinity": "894 W Belmont Ave, Fresno", "types": ["zoo", "point_of_interest", "establishment"], "lat": 36.7522416, "lng": -119.82307930000002}, {"name": "Meux Home Museum", "vicinity": "1007 R St, Fresno", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.739746, "lng": -119.78239050000002}, {"name": "Oso de Oro Park", "vicinity": "5550 N Forkner Ave, Fresno", "types": ["park", "point_of_interest", "establishment"], "lat": 36.8172875, "lng": -119.82962959999998}] }
            attractionImages={ {"FAC Water Tower":image0,"Arte Américas":image1,"Clovis - Big Dry Creek Museum":image2,"Fresno Chaffee Zoo":image3,"Meux Home Museum":image4,"Oso de Oro Park":image5,} }
       />);
  }
}